import { SupportedLanguage } from '@/entities/App';

const exampleSolution: Record<SupportedLanguage, string[]> =
  process.env.NEXT_PUBLIC_APP_ENV === 'prod'
    ? {
        en: [
          'yrvmNdLe4f',
          'sZahowcLtR',
          'Rwd7yLzqCT',
          'Ckhr6bkfYN',
          'dmooQxcECh',
          'cgfM1G7kHQ',
        ],
        es: [
          'zn6VeuyAue',
          'EdQ3pZYZ6L',
          'BoCx466URj',
          'y4fRA3mo6x',
          'f2S7XwnLNx',
          'i2YBGLM5JR',
        ],
        id: [
          'ZXjMWFYGSa',
          'YXxSddEJwY',
          'k32kxcdZ8R',
          'jDmsJiPhsU',
          'naHr1we7Gq',
          'TZwFgouAC8',
        ],
        ja: [],
        ko: [
          'VpCtmhJ1DA',
          'Gy7X8wJfyy',
          'JXaiZ3i8ES',
          'ASuhGL6fL7',
          'Gn3UcmQyji',
          'JoHqko7VEc',
        ],
        th: [
          'EN5b4fixLJ',
          '3D9k9BsTMP',
          'drT9h1MLXi',
          'yFAvwEb5Gt',
          'uZmHQjoWoY',
          'gaLNwKjjRR',
        ],
        vi: [
          'GrMiKRwfXu',
          'UGhc5XWNKo',
          'ETz5gF2vny',
          'CoDsBGFYNi',
          'HJbyq47spA',
          'c1dLvj8Jsy',
        ],
      }
    : {
        en: [
          'EsoDgWjNFC',
          'jwAWE9aQRt',
          'nmsYcsf4DP',
          'tzB57WJoPE',
          'Gz8oXzytn9',
          'BLpbtyVLTU',
        ],
        es: [
          'Z9vVgLGFtU',
          'BDgY8dHoiR',
          'b5gBKyk6nc',
          'Vg4A3eYNEM',
          'ww4WmjaUXu',
          'aDfsmuTMZX',
        ],
        id: [
          'j5wisbRPGU',
          'ZGHpJb2htx',
          'xkSgpjxxAg',
          'n6fvMQEgYy',
          '46fr1fgEKG',
          'DwV1H9v8tC',
        ],
        ja: [],
        ko: [
          'w5JLeGtMqX',
          'SXgTpWJtcE',
          'BC21bWT8i4',
          '79d7bJ9WJR',
          '2HS2vrHTEM',
          'RpeH7GnyWr',
        ],
        th: [
          '1xKALXE7kq',
          'trCxkiKnwc',
          'kduEMjXUjv',
          'aj4S8SQ3t6',
          'QkPkueqhFC',
          'wS2bmnCyZN',
        ],
        vi: [
          'kt6YcsA1VF',
          'ebhEtJ9nv5',
          'spxPHz3HFo',
          'W7BW3Fr2aD',
          'dJ248txDHi',
          'FrNK5ncH5H',
        ],
      };

export default exampleSolution;

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { StaticImageData } from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import iconArrow from '@/assets/icons/left-arrow.svg';
import FlexRow from '@/components/atoms/container/FlexRow';
import Image from '@/components/atoms/Image';
import { SupportedLanguage } from '@/entities/App';
import exampleSolution from '@/resources/exampleSolution';
import { mediaQueries } from '@/styles/theme';
import { logEvent } from '@/utils/firebase';

const Wrapper = styled(FlexRow)`
  width: 100%;
  margin-top: 24px;
  justify-content: center;
  align-items: center;

  ${mediaQueries('mobile')} {
    margin-top: 8px;
    margin-bottom: 48px;
  }
`;

const ImageFence = styled.div`
  overflow: hidden;
  width: 744px;
  margin: 24px 40px;

  ${mediaQueries('mobile')} {
    overflow: scroll;
    width: 100%;
    margin: 0;
    & > div {
      margin: 0 24px;
    }
  }
`;

const ImageDrawer = styled(FlexRow)<{ rightClicked?: boolean }>`
  transition: all ease-in-out 0.3s;
  width: 1510px;

  ${(p) =>
    p.rightClicked &&
    `
    transform: translateX(-756px);
  `}

  a {
    border-radius: 8px;
    border: 1px solid #d3d3d3;

    + a {
      margin-left: 12px;
    }
  }
`;

const ImageAnchor = styled.a`
  display: inline-block;
  width: 240px;
  height: 138px;
  overflow: hidden;
`;

const HandleArrow = styled.button<{ right?: boolean }>`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
  background: center / 24px 24px no-repeat url(${iconArrow.src});
  cursor: pointer;
  ${(p) =>
    p.right &&
    `
      transform: rotate(180deg);
  `}

  ${mediaQueries('mobile')} {
    display: none;
  }
`;

const isStaticImageData = (
  image: string | StaticImageData
): image is StaticImageData => (image as StaticImageData).src !== undefined;

const ExampleSolutionsSwiper: FC = () => {
  const [rightClicked, setRightClicked] = useState<boolean>(false);
  const { lang } = useTranslation('common');

  const [images, setImages] = useState<(StaticImageData | string)[]>([
    '/',
    '/',
    '/',
    '/',
    '/',
    '/',
  ]);

  const imageModules = useMemo(async () => {
    const returnValues = [];

    let image = await import(
      `@/assets/images/landing-images/example-solutions/${lang}_high_1.jpg`
    );
    returnValues.push(image);
    image = await import(
      `@/assets/images/landing-images/example-solutions/${lang}_high_2.jpg`
    );
    returnValues.push(image);
    image = await import(
      `@/assets/images/landing-images/example-solutions/${lang}_middle_1.jpg`
    );
    returnValues.push(image);
    image = await import(
      `@/assets/images/landing-images/example-solutions/${lang}_middle_2.jpg`
    );
    returnValues.push(image);
    image = await import(
      `@/assets/images/landing-images/example-solutions/${lang}_elem_1.jpg`
    );
    returnValues.push(image);
    image = await import(
      `@/assets/images/landing-images/example-solutions/${lang}_elem_2.jpg`
    );
    returnValues.push(image);

    return returnValues;
  }, [lang]);

  useEffect(() => {
    imageModules.then((images) => {
      setImages(images);
    });
  }, [imageModules]);

  const imageAnchorClickHandler = useCallback(() => {
    logEvent('click_qanda_ai', {
      type: 'home_intro_solver_btn',
    });
  }, []);

  return (
    <Wrapper>
      <HandleArrow
        onClick={() => {
          setRightClicked(false);
        }}
      />
      <ImageFence data-testid="example-solution-questions-wrapper">
        <ImageDrawer rightClicked={rightClicked}>
          <ImageAnchor
            href={`${lang}/solutions/${
              exampleSolution[lang as SupportedLanguage][0]
            }`}
            onClick={imageAnchorClickHandler}
          >
            <Image
              image={isStaticImageData(images[0]) ? images[0].src : images[0]}
              width={240}
              height={138}
              alt="example-solution-1"
            />
          </ImageAnchor>
          <ImageAnchor
            href={`${lang}/solutions/${
              exampleSolution[lang as SupportedLanguage][1]
            }`}
            onClick={imageAnchorClickHandler}
          >
            <Image
              image={isStaticImageData(images[1]) ? images[1].src : images[1]}
              width={240}
              height={138}
              alt="example-solution-2"
            />
          </ImageAnchor>
          <ImageAnchor
            href={`${lang}/solutions/${
              exampleSolution[lang as SupportedLanguage][2]
            }`}
            onClick={imageAnchorClickHandler}
          >
            <Image
              image={isStaticImageData(images[2]) ? images[2].src : images[2]}
              width={240}
              height={138}
              alt="example-solution-3"
            />
          </ImageAnchor>
          <ImageAnchor
            href={`${lang}/solutions/${
              exampleSolution[lang as SupportedLanguage][3]
            }`}
            onClick={imageAnchorClickHandler}
          >
            <Image
              image={isStaticImageData(images[3]) ? images[3].src : images[3]}
              width={240}
              height={138}
              alt="example-solution-4"
            />
          </ImageAnchor>
          <ImageAnchor
            href={`${lang}/solutions/${
              exampleSolution[lang as SupportedLanguage][4]
            }`}
            onClick={imageAnchorClickHandler}
          >
            <Image
              image={isStaticImageData(images[4]) ? images[4].src : images[4]}
              width={240}
              height={138}
              alt="example-solution-5"
            />
          </ImageAnchor>
          <ImageAnchor
            href={`${lang}/solutions/${
              exampleSolution[lang as SupportedLanguage][5]
            }`}
            onClick={imageAnchorClickHandler}
          >
            <Image
              image={isStaticImageData(images[5]) ? images[5].src : images[5]}
              width={240}
              height={138}
              alt="example-solution-6"
            />
          </ImageAnchor>
        </ImageDrawer>
      </ImageFence>
      <HandleArrow
        right
        onClick={() => {
          setRightClicked(true);
        }}
      />
    </Wrapper>
  );
};

export default ExampleSolutionsSwiper;

import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import BackgroundWrapper from '@/components/atoms/BackgroundWrapper';
import ExampleSolutionsSwiper from '@/components/organisms/ExampleSolutionsSwiper';
import colors from '@/constants/colors';
import { mediaQueries } from '@/styles/theme';

const Wrapper = styled.div`
  margin: 24px 42px 64px 42px;

  ${mediaQueries('mobile')} {
    margin: 8px 0px 0px 0px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.03em;
  color: ${colors.black};
  white-space: nowrap;

  ${mediaQueries('mobile')} {
    text-align: left;
    white-space: pre-line;
    margin: 0 24px;
  }
`;

const ExampleSolutions: FC = () => {
  const { t } = useTranslation('common');

  return (
    <BackgroundWrapper>
      <Wrapper>
        <Title>{t('qanda_ai.main_examples_title')}</Title>
        <ExampleSolutionsSwiper />
      </Wrapper>
    </BackgroundWrapper>
  );
};
export default ExampleSolutions;
